import { useQuery } from '@tanstack/react-query';
import { GetOwnListWithdrawRequestEntity, WithdrawRequestService } from 'services/openapi';

import { RequestWithdrawQueryKeys } from '../constants';
import { isEmpty } from 'lodash';

type GetListRequestWithdrawOwnDTO = {
  // transactionStatus: 'Tất cả' | 'Thành công' | 'Từ chối' | 'Đang xử lý';
  transactionStatus: 'Thành công' | 'Từ chối' | 'Đang xử lý';
  transactionCode?: string;
  fromTime: string;
  toTime: string;
  page: string;
  pageSize: string;
};

export const useGetRequestWihtdrawOwn = (variables: GetListRequestWithdrawOwnDTO) => {
  const queryKey = [RequestWithdrawQueryKeys.GET_LIST_REQUEST_WITHDRAW_OWN, variables];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    GetOwnListWithdrawRequestEntity,
    Error
  >({
    queryKey,
    queryFn: () =>
      WithdrawRequestService.withdrawRequestControllerGetOwnList(
        variables.fromTime?.trim(),
        variables.toTime?.trim(),
        variables.transactionStatus,
        variables.page,
        variables.pageSize,
      ),
    retry: 0,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: !isEmpty(variables)
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};
